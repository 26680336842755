:root {
  --amplify-font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --amplify-primary-color: #1976d2; /* Button color */
  --amplify-primary-shade: #1565c0; /* Button active color */
  --amplify-primary-tint: #1565c0; /* Link active color */
  --amplify-secondary-color: #4d4d4d; /* Heading, label, and input color */
  --amplify-grey: #5c656b; /* Helper text color */
  --amplify-text-md-sub: 1.8rem; /* Heading font size */
  --amplify-text-md: 1.4rem; /* Sets height for label */
  --amplify-text-sm: 1rem; /* Label font size */
  --amplify-text-xs: 0.9rem; /* Helper text font size */
}

amplify-sign-in {
  width: auto;
}
